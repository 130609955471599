<template>

  <div>
    <button class="btn btn-secondary" @click="showLogs"><i class="fe fe-activity"></i></button>
    <b-modal
      v-model="showLogsModal"
      centered
      title="Activity logs"
      size="lg"
      hide-footer
      id="modal-log-table">
      <template slot="modal-header-close">&nbsp;</template>
      <dimmer :active="fetching">
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter card-table">
            <thead>
              <tr>
                <th>Causer</th>
                <th>Date / Time</th>
                <th>Description</th>
                <th class="w-1"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="text-nowrap"><router-link target="_blank" :to="`/users/${item.causer.customer_id}`">{{truncate(displayName(item.causer))}}</router-link></td>
                <td class="text-nowrap">{{moment(item.createdAt).format('ddd, D MMM YYYY hh:mm A')}}</td>
                <td>{{item.description}}</td>
                <td class="text-nowrap">
                  <a
                    v-if="item.changes"
                    class="link"
                    href="#"
                    @click="openDetailModal(item.changes)">View ({{Object.keys(item.changes.attributes).length}} changes)</a>
                  <span v-else>No Changes</span>
                </td>
              </tr>
              <tr v-if="items.length === 0">
                <td colspan="4">There are no results matching your criteria.</td>
              </tr>
            </tbody>
          </table>

          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </dimmer>

      <log-detail-modal v-model="selectedChanges" :show.sync="showLogDetailModal"></log-detail-modal>
    </b-modal>
  </div>

</template>

<script>

import LogDetailModal from '@/components/LogDetailModal';
import Pagination from '@/components/Pagination';
import fetch from '@/mixins/fetch';
import {activitylogSubject} from '@/services';

export default {

  components: {

    LogDetailModal,
    Pagination,
  },
  mixins: [

    fetch,
  ],
  props: [

    'value',
  ],
  data() {
    return {

      selectedChanges: null,
      showLogDetailModal: false,
      showLogsModal: false,
    };
  },
  methods: {

    fetchData(page) {
      const params = {

        limit: this.limit,
        page,
        subject_id: this.value.id,
        subject_type: this.value.modelName || '',
      };

      try {
        return activitylogSubject.getByParameters(params);
      }
      catch (e) {
        console.error(e);
      }
    },
    openDetailModal(changes) {
      this.selectedChanges = changes;
      this.showLogDetailModal = true;
    },
    showLogs() {
      this.refresh();
      this.showLogsModal = true;
    },
  },
};

</script>

<style>

    #modal-log-table .modal-body {
        padding: 0;
    }

</style>
